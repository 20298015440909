exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .user-avatar_user-thumbnail_IwOnc {\r\n    width: 2rem;\r\n    height: 2rem;\r\n    border-radius: calc(0.5rem / 2);\r\n    vertical-align: middle;\r\n    -webkit-box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.15);\r\n            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.15);\r\n}\r\n", ""]);

// exports
exports.locals = {
	"user-thumbnail": "user-avatar_user-thumbnail_IwOnc",
	"userThumbnail": "user-avatar_user-thumbnail_IwOnc"
};
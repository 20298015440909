import PropTypes from 'prop-types';
import React from 'react';
import styles from './photon-connection-info-popup.css';
import PhotonToReconnect from './photon-to-reconnect/photon-to-reconnect.jsx';

class PhotonConnectionInfoPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            headerText: { id: '', defaultText: '' },
            photons: []
        };

        this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
        this.noPhotonConnected = this.noPhotonConnected.bind(this);
        this.onPhotonConnected = this.onPhotonConnected.bind(this);
        this.onPhotonDisconnected = this.onPhotonDisconnected.bind(this);
    }

    componentDidMount() {
        const { socket } = this.props;
        if (socket == null) return;
        socket.on('photonConnected', this.onPhotonConnected);
        socket.on('photonDisconnected', this.onPhotonDisconnected);

        socket.noPhotonConnectedCallbacks.push(this.noPhotonConnected);
    }

    noPhotonConnected() {
        this.setState({
            isVisible: true,
            headerText: { id: 'photon.noConnectionToRobot', defaultText: 'No connection to robot' },
            photons: []
        });
    }

    onPhotonConnected(robotInfo) {
        console.log('connected', robotInfo);
        const photons = this.state.photons.filter(p => p.name !== robotInfo.name);
        this.setState({
            isVisible: photons.length > 0,
            photons: photons
        });
    }

    onPhotonDisconnected(robotInfo) {
        console.log('disconnected', robotInfo);

        const photons = this.state.photons;

        const robotAlreadyInArray = photons.some(x => x.name === robotInfo.name);
        if (!robotAlreadyInArray) {
            photons.push({ name: robotInfo.name, shortName: robotInfo.shortName });
        }

        this.setState({
            isVisible: true,
            headerText: { id: 'photon.connectionToRobotLost', defaultText: 'Connection to Photon has been lost...' },
            photons: photons
        });
    }

    handleCloseButtonClick() {
        this.setState({ isVisible: false });
    }

    render() {
        const { socket } = this.props;
        const { isVisible, headerText, photons } = this.state;

        return <div className={`${styles.Container} ${isVisible ? styles.Showed : ''}`}>
            <div className={styles.CloseButton} onClick={this.handleCloseButtonClick} />
            <div>
                {
                    socket.getTranslatedText(headerText.id, headerText.defaultText)
                }
            </div>
            <div className={`${styles.PhotonsContainer} ${photons.length > 0 ? styles.PhotonsContainer__WithRobots : ''}`}>
                {
                    photons.map(p => <PhotonToReconnect key={p.name}
                                                        socket={socket}
                                                        name={p.name}
                                                        shortName={p.shortName} />)
                }
            </div>
        </div>;
    }
}

PhotonConnectionInfoPopup.propTypes = {
    socket: PropTypes.any
};

export default PhotonConnectionInfoPopup;

import PropTypes from 'prop-types';
import React from 'react';

import style from './photon-multi.css';

class PhotonMulti extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            photons: []
        };

        this.onPhotonsArrayChanged = this.onPhotonsArrayChanged.bind(this);
    }


    componentDidMount () {
        const {socket} = this.props;
        if (socket == null) return;
        socket.on('photonConnected', this.onPhotonsArrayChanged);
        socket.on('photonDisconnected', this.onPhotonsArrayChanged);
    }

    onPhotonsArrayChanged () {
        const {socket} = this.props;

        const photons = socket.photons;
        this.setState({photons});
    }


    render () {
        const query = new URLSearchParams(location.search);
        const mode = query.get('mode');
        if (mode !== 'multi') return null;

        const {photons} = this.state;
        const {socket} = this.props;

        return (
            <div className={style.PhotonMainContainer}>
                <div className={style.PhotonHeader}>
                    <div>
                        {socket.getTranslatedText('photon.connectedPhotons', 'Connected Photons')}
                    </div>
                </div>
                <div className={style.PhotonMultiContainer}>
                    <div className={style.PhotonColumnContainer}>
                        <PhotonArrayElement
                            number={1}
                            renderLine
                            socket={socket}
                            photon={photons[0]}
                        />
                        <PhotonArrayElement
                            number={2}
                            renderLine
                            socket={socket}
                            photon={photons[1]}
                        />
                        <PhotonArrayElement
                            number={3}
                            socket={socket}
                            renderLine
                            photon={photons[2]}
                        />
                        <PhotonArrayElement
                            number={4}
                            socket={socket}
                            photon={photons[3]}
                        />
                    </div>
                    <div className={style.PhotonColumnContainer}>
                        <PhotonArrayElement
                            number={5}
                            renderLine
                            socket={socket}
                            photon={photons[4]}
                        />
                        <PhotonArrayElement
                            number={6}
                            socket={socket}
                            renderLine
                            photon={photons[5]}
                        />
                        <PhotonArrayElement
                            number={7}
                            socket={socket}
                            renderLine
                            photon={photons[6]}
                        />
                        <PhotonArrayElement
                            number={8}
                            socket={socket}
                            photon={photons[7]}
                        />
                    </div>
                </div>
            </div>

        );
    }
}

PhotonMulti.propTypes = {
    socket: PropTypes.any
};

const PhotonArrayElement = ({number, photon, renderLine, socket}) => (
    <div className={`${style.PhotonElement} ${renderLine ? style.PhotonElementBottomLine : null}`}>
        <div>{number}</div>
        <div className={photon != null ? style.PhotonColorConnected : style.PhotonColorNotConnected}>
            {photon != null ? photon.shortName : '–––'}
        </div>
        <div
            className={style.PhotonSearchIcon}
            onClick={() => sendImHereInstruction(socket, number)}
        >
            <PhotonSearchIcon connected={photon != null}/>
        </div>
    </div>);

const sendImHereInstruction = (socket, photonNumber) => {
    socket.sendInstructionToRobot({sound: 'im_here', confirmationMode: 0}, photonNumber - 1, true);
};

const PhotonSearchIcon = ({connected}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        fill={connected ? 'hsla(225, 15%, 40%, 1)' : 'hsla(0, 0%, 0%, 0.15)'}
        height="20"
        viewBox="0 0 24 24"
    >
        <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
        />
        <path
            d="M0 0h24v24H0z"
            fill="none"
        />
    </svg>
);

export default PhotonMulti;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".photon-connection-info-popup_Container_2CXma {\n    width: 75%;\n    text-align: center;\n    display: grid;\n    grid-template-columns: 1fr 2fr;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    margin: 4px;\n}\n\n.photon-connection-info-popup_ConnectButtonContainer_3vwsr {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n\n.photon-connection-info-popup_ConnectButton_1g0FO {\n    padding: 8px;\n    color: white;\n    border: 1px solid white;\n    border-radius: 4px;\n    -webkit-transition: background-color 0.15s ease;\n    -o-transition: background-color 0.15s ease;\n    transition: background-color 0.15s ease;\n}\n\n.photon-connection-info-popup_ConnectButton_1g0FO:hover {\n    background-color: rgba(255, 255, 255, 0.1);\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"Container": "photon-connection-info-popup_Container_2CXma",
	"container": "photon-connection-info-popup_Container_2CXma",
	"ConnectButtonContainer": "photon-connection-info-popup_ConnectButtonContainer_3vwsr",
	"connectButtonContainer": "photon-connection-info-popup_ConnectButtonContainer_3vwsr",
	"ConnectButton": "photon-connection-info-popup_ConnectButton_1g0FO",
	"connectButton": "photon-connection-info-popup_ConnectButton_1g0FO"
};
import PropTypes from 'prop-types';
import React from 'react';
import styles from './photon-connection-info-popup.css';

class PhotonToReconnect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            connecting: false
        };
        this.handleConnectButtonClick = this.handleConnectButtonClick.bind(this);
    }

    handleConnectButtonClick() {
        const { connecting } = this.state;

        if (connecting) {
            return;
        }

        const { socket, name } = this.props;
        socket.sendConnectInstruction(name);
        this.setState({ connecting: true });
    }

    render() {
        const { shortName, socket } = this.props;
        const { connecting } = this.state;

        return <div className={styles.Container}>
            <div>
                {shortName}
            </div>
            <div className={styles.ConnectButton} onClick={this.handleConnectButtonClick}>
                {
                    connecting ?
                        socket.getTranslatedText('photon.connecting', 'Connecting...') :
                        socket.getTranslatedText('photon.reconnect', 'Reconnect')
                }
            </div>
        </div>;
    }
}

PhotonToReconnect.propTypes = {
    name: PropTypes.string,
    shortName: PropTypes.string,
    socket: PropTypes.any
};

export default PhotonToReconnect;
